import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

const options = [
  {
    value: 'released',
    label: 'Released',
  },
  {
    value: 'manager_pending_setup',
    label: 'Configuration',
  },
  {
    value: 'managed_end_user',
    label: 'Full restrictions',
  },
  {
    value: 'managed_light_restrictions',
    label: 'Light restrictions',
  },
  {
    value: 'demo_display',
    label: 'Demo display',
  },
];

type Props = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export const DeviceMgmtModeSelect = ({ value, onChange, disabled }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      disabled={disabled}
      sx={{ maxWidth: ({ spacing }) => spacing(32) }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
