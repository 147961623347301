import {
  Box,
  Button,
  CircularProgress,
  Typography,
  styled,
} from '@mui/material';
import { PageContent } from 'components/_extra/generic';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetByIdQuery,
  useMdmPushMutation,
  useUpdatePatternTypesMutation,
  useUpdateTagsMutation,
} from 'store/api/devices.api';
import WatchAssignModal from '../ProfileLookupResults/WatchAssignModal';
import { useEffect, useState } from 'react';
import { routes } from 'nav';
import ReactJson from 'react-json-view';
import { PatternTypeGroup } from '../ProfileLookupResults/DeviceInfoModal.extra';
import { DevicePatternType } from 'models/devices.model';
import { DeviceMgmtModeSelect } from './DeviceMgmtModeSelect';

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

const DeviceData = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { deviceId } = useParams<{ deviceId: string }>();
  const { data, isLoading, isFetching } = useGetByIdQuery(Number(deviceId), {
    skip: !deviceId,
  });

  const [updatePatternTypes, { isLoading: isUpdating }] =
    useUpdatePatternTypesMutation();
  const [
    updateTags,
    { isLoading: areTagsUpdating, isSuccess: tagsAreUpdated },
  ] = useUpdateTagsMutation();
  const [mdmPush] = useMdmPushMutation();

  const goToProfile = () => {
    if (data) {
      navigate(routes.admin.lookupResults.href(data.account_id));
    }
  };

  const handleRefreshPatternTypeChange = (
    type: 'location' | 'heart',
    value: DevicePatternType,
  ) => {
    if (!data) {
      return;
    }

    updatePatternTypes({
      deviceId: data.id,
      heartRateRefresh:
        type === 'heart' ? value : data.heart_rate_refresh_pattern_type,
      locationRefresh:
        type === 'location' ? value : data.location_refresh_pattern_type,
    });
  };

  const handleMgmtModeChange = (value: string) => {
    if (!data) {
      return;
    }

    if (value === 'released') {
      updateTags({
        deviceId: data.id,
        tags: [],
      });
      return;
    }

    updateTags({
      deviceId: data.id,
      tags: [value],
    });
  };

  useEffect(() => {
    if (tagsAreUpdated) {
      mdmPush({ deviceId: Number(deviceId) });
    }
  }, [tagsAreUpdated]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          mt: 4,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return (
      <Typography variant="h6" textAlign="center">
        Device not found
      </Typography>
    );
  }

  return (
    <PageContent sx={{ p: 2 }}>
      <Grid>
        <Typography color="primary" fontWeight={600}>
          Device ID:
        </Typography>
        <span>{data.id}</span>
        <Typography color="primary" fontWeight={600}>
          Is active:
        </Typography>
        <span>{data.active ? 'Yes' : 'No'}</span>
        <Typography color="primary" fontWeight={600}>
          Account ID:
        </Typography>
        <Box display="flex" alignItems="center" gap={1}>
          {data.account_id}
          <Button size="small" variant="text" onClick={goToProfile}>
            See profile
          </Button>
        </Box>
        <Typography color="primary" fontWeight={600}>
          Name:
        </Typography>
        <span>{data.name}</span>
        <Typography color="primary" fontWeight={600}>
          Model:
        </Typography>
        <span>{data.model}</span>
        <Typography color="primary" fontWeight={600}>
          System:
        </Typography>
        <span>{data.system}</span>
        <Typography color="primary" fontWeight={600}>
          Actions:
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ width: 'fit-content' }}
          onClick={() => setIsOpen(true)}
        >
          Reassign device
        </Button>
        <Typography color="primary" fontWeight={600}>
          Identifiers
        </Typography>
        <ReactJson
          src={data.identifiers}
          displayDataTypes={false}
          collapsed
          name="identifiers"
        />
        <Typography color="primary" fontWeight={600}>
          Push configs
        </Typography>
        <ReactJson
          src={data.push_configurations}
          displayDataTypes={false}
          collapsed
          name="push_configurations"
        />
        <Typography color="primary" fontWeight={600}>
          Location Refresh
        </Typography>
        <PatternTypeGroup
          value={data.location_refresh_pattern_type}
          onChange={(v) => handleRefreshPatternTypeChange('location', v)}
          disabled={isFetching || isUpdating}
        />
        <Typography color="primary" fontWeight={600}>
          Heart Rate Refresh
        </Typography>
        <PatternTypeGroup
          value={data.heart_rate_refresh_pattern_type}
          onChange={(v) => handleRefreshPatternTypeChange('heart', v)}
          disabled={isFetching || isUpdating}
        />
        <Typography color="primary" fontWeight={600}>
          Device Mgmt mode
        </Typography>
        <DeviceMgmtModeSelect
          value={
            data.tags && data.tags.length !== 0 ? data.tags[0] : 'released'
          }
          onChange={handleMgmtModeChange}
          disabled={isFetching || areTagsUpdating}
        />
      </Grid>
      <WatchAssignModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        deviceId={data?.id}
      />
    </PageContent>
  );
};

export default DeviceData;
