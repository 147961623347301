import { ButtonGroup, Button } from '@mui/material';
import { DevicePatternType } from 'models/devices.model';

export const PatternTypeGroup = ({
  value,
  onChange,
  disabled,
}: {
  value: DevicePatternType;
  onChange: (value: DevicePatternType) => void;
  disabled?: boolean;
}) => {
  return (
    <ButtonGroup disabled={disabled}>
      <Button
        variant={value === 'default' ? 'contained' : 'outlined'}
        onClick={() => onChange('default')}
        size="small"
      >
        Default
      </Button>
      <Button
        variant={value === 'hybrid' ? 'contained' : 'outlined'}
        onClick={() => onChange('hybrid')}
        size="small"
      >
        Hybrid
      </Button>
      <Button
        variant={value === 'continuous' ? 'contained' : 'outlined'}
        onClick={() => onChange('continuous')}
        size="small"
      >
        Continuous
      </Button>
    </ButtonGroup>
  );
};

export type DeviceMgmtStatus = 'released' | 'partial-lock' | 'lock';

export const DeviceMgmtMode = ({
  value,
  onChange,
  disabled,
}: {
  value: DeviceMgmtStatus;
  onChange: (value: DeviceMgmtStatus) => void;
  disabled?: boolean;
}) => {
  return (
    <ButtonGroup disabled={disabled}>
      <Button
        variant={value === 'released' ? 'contained' : 'outlined'}
        onClick={() => onChange('released')}
      >
        Released
      </Button>
      <Button
        variant={value === 'partial-lock' ? 'contained' : 'outlined'}
        onClick={() => onChange('partial-lock')}
      >
        Partially locked down
      </Button>
      <Button
        variant={value === 'lock' ? 'contained' : 'outlined'}
        onClick={() => onChange('lock')}
      >
        Locked down
      </Button>
    </ButtonGroup>
  );
};

export const computeDeviceMgmtStatusFromTags = (
  tags: string[],
): DeviceMgmtStatus => {
  if (tags.includes('managed_end_user')) {
    return 'lock';
  }

  if (tags.includes('managed_pending_setup')) {
    return 'partial-lock';
  }

  return 'released';
};

export const computeTagsFromDeviceMgmtStatus = (
  status: DeviceMgmtStatus,
  prevTags: string[] = [],
): string[] => {
  if (status === 'released') {
    return prevTags.filter(
      (item) => item !== 'managed_end_user' || 'managed_pending_setup',
    );
  }

  if (status === 'partial-lock') {
    return [
      ...prevTags.filter((item) => item !== 'managed_end_user'),
      'managed_pending_setup',
    ];
  }

  if (status === 'lock') {
    return [
      ...prevTags.filter((item) => item !== 'managed_pending_setup'),
      'managed_end_user',
    ];
  }

  return prevTags;
};
